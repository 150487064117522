.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

@media (max-width: 992px) {
  .footer-copyright {
    padding-bottom: 80px !important;
  }
}