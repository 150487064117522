.page-category {
    .subcategories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 3rem 0;

        > div {
            padding: 0.5rem;
            margin: 0.5rem;
            width: 130px;
            transition: transform 220ms ease-in-out;

            &:hover {
                transform: translateY(-5px);
            }
        }

        img {
            margin-bottom: 0.5rem;
        }

        h5 {
            text-align: center;
            margin-bottom: 0;

            a {
                color: #707070;
            }
        }
    }

    .mosaicat-mobile {
        list-style: none;
    }

    #category-description {
        width: 100%;
    }
}



@media (max-width: 992px) {
    .block-category {
        margin-top: 1rem;
    }
}