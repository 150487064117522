.products {
    @extend .row;
    align-items: stretch;
}

.product-miniature {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-4;

    .card-product {
        //box-shadow: 0 5px 10px #0000000D;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.30);
        border: solid 1px green !important;
        border-radius: 20px;
        padding-top: 20px;
    }

    .card {
        border: none;
    }

    .rc {
        background: none;
    }

    .product-price-and-shipping, .product-title {
        text-align: start !important;
    }

    .product-title {
        font-family: $font-secondary;
    }

    .card-body {
        position: relative;
    }

    .product-description {
        .product_manufacturer_name{
            font-size: 13px;
        }
        > div:last-child  {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .add-to-cart-miniature {
            width: max-content;
        }

        .form-add-to-cart-miniature {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .input-group {
                display: none;
            }
        }

        .btn-add-to-cart-miniature {
            background-color: $color-secondary;
            border-radius: 5px;
        }
    }

    .other-region-product {
        p {
            font-size: 11px;
            font-style: italic;
            color: #A8A8A8;
            margin-bottom: 0;

            &:before {
                content: url("/themes/classic-rocket/assets/img/icon-material-location-on.svg");
                margin-right: 5px;
            }
        }
    }

    .savoie-product {
        p {
            font-size: 11px;
            font-style: italic;
            color: #A8A8A8;
            margin-bottom: 0;

            &:before {
                content: url("/themes/classic-rocket/assets/img/drapeau-savoie.png");
                margin-right: 5px;
            }
        }
    }
}

.slick-slide .product-miniature {
    max-width: 100%;
}

/* Product miniature*/
.card-product.card {
    height: 100%;
    position: relative;
    background-color: $pm-bg-color;
    border-width: $pm-border-width;
    border-color: $pm-border-color;

    &:hover {
        .highlighted-informations {
            transform: translateY(0);
        }

    }

    .discount {
        display: none;
    }
}

.card-img-top {
    overflow: hidden;

    .product-thumbnail img {
        width: 100%;
    }

}

.highlighted-informations {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $pm-highlight-bg-color;
    transform: translateY(100%);
    transition: transform .3s;

    .card-product:hover & {
        transform: translateY($pm-highlight-translateY);
    }
}

.quick-view {
    cursor: pointer;
}

.product-flags {
    //position: absolute;
    //height: 75px;
    //width: 75px;
    //top: 0;
    margin-bottom: 0;
    //z-index: 1;
    //left: -$spacer/4;
    //display: flex;
    //flex-direction: column;
    //align-items: flex-start;

    .new {
        background-image: url(/themes/classic-rocket/assets/img/icon-new.svg);
        bottom: 0;
    }

    .out_of_stock {
        background-image: url(/themes/classic-rocket/assets/img/icon-epuise.svg);
        top: 0;
    }

    .new, .out_of_stock {
        position: absolute;
        height: 75px;
        width: 75px;
    }
}

.page-content--product .product-flags {
    left: 0;
}

.product-flag {
    padding: $spacer/4;
    color: transparent;

    &.discount-product {
        background: $tertiary;
    }
}

.color {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .2);
    border: 1px solid rgba(0, 0, 0, .05);
    cursor: pointer;
    background-size: contain;
}

.product-title {
    color: $pm-name-color;
    font-size: $pm-name-font-size;
    text-decoration: none;
    text-align: center;
    font-weight: $pm-name-font-weight;

    & a {
        color: $pm-name-color;

        &:hover {
            color: $pm-name-hover-color;
            border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
        }

    }

    .card-product:hover & {
        color: $pm-name-hover-color;

        & a {
            color: $pm-name-hover-color;
        }
    }
}

/* PRODUCT PAGE */

.products-imagescover {
    box-shadow: $card-box-shadow;;
}

.product-img:not(:first-child) .rc {
    display: none;
}

.slick-initialized .product-img .rc {
    display: block;
}

.product-thumbs {
    width: $product-thumb-wrap-width;
    height: $product-thumb-wrap-height;
    margin: 0 auto;

    &[data-count="2"] {

        width: $product-thumb-wrap-width-2;
    }

    .slick-slide > div {
        cursor: pointer;

    }

    &:not(.slick-initialized) > div:not(:first-child) {
        display: none;
    }

}

.product-thumb {
    margin: $product-thumb-margin;
    width: $product-thumb-width;
    height: $product-thumb-height;
    border: 2px solid transparent;

    &.slick-current {
        border: 2px solid theme-color('primary');
    }
}

.btn-zoom {
    position: absolute;
    right: $spacer;
    bottom: $spacer;
    cursor: pointer;
    display: none;
    padding: 0;
    line-height: 1;

    & .material-icons {
        font-size: 3*$font-size-base;
    }
}

.images-container:hover .btn-zoom {
    display: block;
}

.label-color {
    line-height: 1;
    margin-right: $spacer/2;
}

.product-variants-item .color {
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
}

.input-color:checked + span, .input-color:hover + span, .input-radio:checked + span, .input-radio:hover + span {
    border-color: $colorbox-border-color;
}

//product prices
.product__product-price.has-discount {
    .current-price {
        display: inline-block;
    }
}

.price {
    color: $product-price-current-color;
    font-size: $product-price-current-font-size;
    font-weight: $product-price-current-font-weight;
    font-family: $product-price-current-font-family;

    &.current-price-discount {
        color: $product-price-discount-color;
        font-size: $product-price-discount-font-size;
        font-weight: $product-price-discount-font-weight;
        font-family: $product-price-discount-font-family;
    }
}

.regular-price {
    color: $product-price-regular-color;
    font-size: $product-price-regular-font-size;
    font-weight: $product-price-regular-font-weight;
    font-family: $product-price-regular-font-family;
    text-decoration: line-through;
}

.discount {
    text-transform: uppercase;
    color: #FFF;
    background-color: $product-price-discount-color;
    font-weight: 600;
    display: inline-block;
    padding: .25*$spacer;
}

//product add to cart
.btn-add-to-cart {
    //@extend .d-flex;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
}

//product tabs
.product-tabs {
    .nav-tabs {
        font-size: $font-size-base * .9;
    }

    //@extend .card;
    //@extend .card-block
    //box-shadow:$card-box-shadow;;
}

.card--reassurance {
    box-shadow: $card-box-shadow;;
}

// modal add to cart
.cart-content {
    padding: $spacer;
    background: theme-color('light');
}

//product pack
.pack-product-container {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    padding: 0 $spacer;
    font-size: $font-size-sm;
}

.mask {
    width: 55px;
}

.thumb-mask {
    padding: $spacer 0;
}

.pack-product__img {
    border: 1px solid $border-color;
}

.pack-product-name {
    @extend .flex-grow-1;
    @extend .mx-4;
}

.pack-product-quantity {
    border-left: 1px solid $border-color;
}

.pack-product-price {
    padding-right: $spacer/2;

}

.pack-product-quantity {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .align-self-stretch;
    padding-left: $spacer;
}

//modal cart
.modal-cart__image {
    margin-right: $spacer;
    max-width: 200px;

    height: auto;
}

.modal-cart__name {
    color: $primary;
    font-weight: $font-weight-bold;
}

.product-total {
    font-weight: $font-weight-bold;
}

.cart-content {
    > p:not(:last-child) {
        margin-bottom: $spacer/2;
    }
}

@include media-breakpoint-mobile {
    .modal-cart__image {
        max-width: 120px;
    }
    .blockcart-modal {
        .divide-right {
            display: flex;
            justify-content: center;
        }

        .modal-footer {
            position: fixed;
            bottom: 0;
            background: #fff;
            justify-content: center;
            left: 0;
            width: 100%;
            box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
        }

        .modal-body {
            margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
        }
    }
}

@include media-breakpoint-down(xs) {
    .modal-cart__image {
        max-width: 70px;
    }
}

//spinner add to cart
.btn-add-to-cart {
    .btn-add-to-cart__spinner {
        @extend .spinner-border;
        width: $spinner-width-addtocart;
        height: $spinner-height-addtocart;
        border-width: $spinner-borderwidth-addtocart;
        display: none;
    }

    &.is--loading {
        .btn-add-to-cart__spinner {
            display: inline-block;
        }

        .btn-add-to-cart__icon {
            display: none;
        }
    }
}

.products-selection {
    justify-content: space-between;
    align-items: center;
}

.pack-miniature-item {
    margin-bottom: $spacer / 2;
}

.ratio1_1 {
    display: block;
}

.pack-product-name {
    margin: 0 $spacer * 1.5;
    flex-grow: 1;
}

.product-miniature {
    margin-bottom: $spacer;
}

.product__card-img {
    position: relative;
}

.product__card-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.highlighted-informations {
    text-align: center;
    padding: $spacer / 2;
}

.quantity__label {
    margin-bottom: 0;
    margin-top: $spacer;

}

.product-add-to-cart {
    .product-quantity {
        margin-top: -($spacer);
    }

    .qty {
        margin: 0 $spacer;
        margin-top: $spacer;

    }

    .add {
        margin-top: $spacer;
    }
}

.product-customization {
    margin-bottom: $spacer * 1.5;
}

.product-discounts {
    margin-bottom: $spacer * 1.5;
}

.product__quantity {
    margin-bottom: 0;
}

.sort-by-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.total__item-show {
    text-align: center;
    font-size: $font-size-sm;
    margin-top: $spacer;
}

.variant-links {
    display: flex;
    justify-content: center;
}

.product__download {
    text-transform: uppercase;
}

.card--address {
    height: 100%;
    text-align: center;
    justify-content: center;
}

.invoice__address {
    margin-top: $spacer;
}

.invoice-address {
    height: 100%;
    text-align: center;
    justify-content: center;
}

.nav-tabs {
    justify-content: center;
}