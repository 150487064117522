.page-product {
    .swiper-container {
        z-index: 0;
    }

    @media (min-width: 768px) {
        .product-miniature {
            height: 450px;
        }
    }
}