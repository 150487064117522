// "Toutes nos marques"
.container-brandlist-view_more {
    > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > div {
            margin: 10px;
            transition: transform 330ms ease;

            &:hover {
                transform: translateY(-10px);
            }
        }
    }

    > a {
        display: flex;
        align-items: center;
        width: max-content;
        margin-left: auto;
        font-size: 1rem;

        &:after {
            content: url("/themes/classic-rocket/assets/img/icon-feather-arrow-down-circle.svg");
            margin-left: 0.5rem;
        }
    }
}

.section-departement {
    .elementor-row {
        justify-content: center;
    }
}

.product-selection-slider {
    .slick-slider {
        .slick-prev:before, .slick-next:before {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}

//@media (min-width: 575px) {
    // Carrousel "Notre sélection"
    .product-selection-slider {

        .slick-prev:before, .slick-next:before {
            background-color: $color-tertiary;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }

        .slick-prev:before {
            left: 10px;
            padding: 2px 15px 6px 13px;
        }

        .slick-next:before {
            right: 10px;
            padding: 2px 14px 6px 16px;
        }

        .slick-slide-inner {
            .product-miniature {
                padding: 0 15px !important;
            }
        }

    }
//}

@media (min-width: 768px) {
    // Carrousel "Notre sélection"
    .product-selection-slider {
        .slick-slider {
            .slick-slide-inner {
                .product-miniature {
                    height: 490px;
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .section-departement {
        .elementor-column-wrap {
            padding: 5px !important;
        }
    }
}

@media (min-width: 1200px) {
    .product-selection-slider {
        .slick-prev:before {
            left: -57px;
        }

        .slick-next:before {
            right: -57px;
        }

    }
}




