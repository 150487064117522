.l-header{
  background-color: $header-bg;
  margin-bottom: 0;
  box-shadow: $header-box-shadow;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}

// MegaMenu
.ets_mm_megamenu .mm_columns_ul {
  padding: 35px 30px 25px 30px !important;
  box-shadow: 0 3px 15px #00000029;
  border: none !important;
}

.mm_menu_content_title {
  padding: 7px !important;
}

.layout_layout1 .ets_mm_megamenu_content {
  border-top: none !important;
  border-bottom: none !important;
}

.layout_layout1 .mm_menus_li:nth-child(2) {
  border-left: none !important;
}

.layout_layout1 .mm_menus_li, .layout_layout1 .mm_menus_li:last-child {
  border-right: none !important;
}

.ets_mm_megamenu_content_content {
  background-color: #c2dfdb;
}

.mm_menus_ul {
  margin: auto !important;
  width: max-content !important;
}

.mm_menu_content_title {
  text-transform: capitalize;
}

.mm_blocks_li {
  .ets_mm_block .h4 {
    color: #43B990 !important;
    text-transform: capitalize;
    border-bottom: none;
  }
}

.socialNetworkMobileMenu {
  div {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
  }

  i {
    font-size: 2rem;
    margin: 0 20px;
  }

  a {
    color: grey;
  }
}

@media (min-width: 550px) and (max-width: 992px) {
  #btn-display-search-bar {
    left: 36% !important;
  }

  .bloc-panier {
    right: 36% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .layout_layout1 .ets_mm_megamenu_content {
    background: initial !important;
  }

  #menudubas .ets_mm_megamenu.hook-default {
    border-radius: 50%;
  }

  .elementor-2170101 .elementor-element.elementor-element-aa26048 .elementor-cart__toggle .elementor-button {
    padding: 2px 5px 5px 5px !important;
  }
}

@media (min-width: 992px) {
  .mm_blocks_li {
    .product-miniature {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    .thumbnail-container {
      .thumbnail {
        width: auto !important;
        border: none !important;
      }
    }
  }

  #btn-display-search-bar {
    display: none;
  }
}

@media (min-width: 1200px) {
  .socialNetworkMobileMenu {
    display: none;
  }
}

@media (max-width: 768px) {
  .bloc-titre-site-barre-recherche {
    width: 0 !important;
  }
}

@media (max-width: 1200px) {
  .block-click-collect-menu-top {
    > .elementor-container > .elementor-row {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  .block-click-and-collect, .block-menu-top {
    width: max-content !important;
  }

  .search-bar {
    z-index: 999 !important;
  }

  .changestatus .mm_menus_li {
    border-top: none !important;
  }
}

@include media-breakpoint-mobile {
  .close_menu {
    background-color: $color-primary!important;
  }

  .mm_block_type_product {
    display: none;
  }
}

@include media-breakpoint-mobile {
  #menudubas {
    position: fixed;
    bottom: 0;
    width: max-content !important;
    margin: 0 auto;
    border-radius: 10px;
    left: 0 !important;
    right: 0;

    .ybc-menu-toggle {
      text-align: center;
    }

    .ybc-menu-button-toggle_icon {
      margin-right: -2px;
    }

    .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
      padding: 0;
    }

    .ets_mm_megamenu.hook-default {
      margin-top: 0;
      padding: 0 5px;
    }

    .layout_layout1 .ets_mm_megamenu_content {
      background-color: transparent;
    }

    .menudubas-enfant {
      z-index: 1000;

      > .elementor-widget-container {
        border-radius: 10px;
        width: 200px;
      }
    }
  }

  .bloc-panier {
    right: 24%;
    position: fixed !important;
    bottom: 21px;
    z-index: 999;

    a {
      border-radius: 10px;
    }
  }

  .display-search-bar {
    display: block!important;
    position: fixed!important;
    bottom: 100px;
    right: 50%;
    left: 50%;
    padding: 1rem;
    background-color: #c2dfdb;
    transform: translate(-50%);
  }

  #btn-display-search-bar {
    width: 32px;
    height: 32px;
    position: fixed;
    bottom: 21px;
    left: 26%;
    z-index: 999;
    background-color: $color-primary;

    img {
      width: 100%;
    }
  }

  // Style BO pour menu mobile
  .elementor-sticky--active {
    z-index: 2;
  }

  ul.mm_menus_ul.active {
    background-color: #C2DFDB;
  }

  .elementor-2170101 .elementor-element.elementor-element-8c32215 {
    width: 100% !important;
    max-width: initial !important;
    height:100%;
  }

  .elementor-widget-ajax-search {
    top: 0;
  }

  #menudubas {
    z-index: 999999;
    border-radius:100px;
  }

  .elementor-2170101 .elementor-element.elementor-element-281a2d9  {
    background-color: #c2dfdb;
    border-radius: 50% !important;
  }

  .elementor-2170101 .elementor-element.elementor-element-281a2d9  .elementor-widget-container {
    background-color: #c2dfdb;
    border-radius: 50%;
  }

  .ybc-menu-toggle {
    padding: 29px 12px;
  }

  .ets_mm_megamenu_content_content {
    background-color: transparent;
  }

  #btn-display-search-bar {
    width: 120px;
    height: 38px;
    position: fixed;
    bottom: 25px;
    left: 25%;
    z-index: 999;
    background-color: #c2dfdb;
    padding: 4px 80px 15px 10px;
    border-radius: 50px 0px 0px 50px;

    img:hover {
      cursor: pointer;
    }
  }

  .elementor-2170101 .elementor-element.elementor-element-aa26048 .elementor-cart__toggle .elementor-button {
    background-color: #c2dfdb;
    border-width: 0px;
    padding: 5px 10px 5px 5px;
    border-radius: 0 50px 50px 0;
  }

  .elementor-2170101 .elementor-element.elementor-element-aa26048 .elementor-cart__toggle .elementor-button {
    padding: 2px 15px 5px 80px;
  }

  .bloc-panier {
    right: 25%;
    position: fixed!important;
    bottom: 25px;
    z-index: 999;
    border-radius: 0 50px 50px 0;
    padding: 0 10px 0 80px;
    background-color: #c2dfdb;
  }

  .elementor-1020101 .elementor-element.elementor-element-eaa3200 .elementor-cta-content {
    z-index: 1;
  }

  .checkout {
    padding: 1rem 1rem 7rem 1rem;
  }

  .checkout.cart-detailed-actions {
    z-index: 1;
  }
}

.customFixedElToScroll {
  position: fixed !important;
  width: 100% !important;
}

.customFixedElToScrollMobile {
  top: 99px !important;
}

.customFixedElToScrollDesktop {
  top: 180px !important;
}

@media (min-width: 1200px) {
  #menudubas {
    z-index: 1;
  }

  .ets_mm_megamenu .mm_columns_ul {
    top: 200% !important;
  }

  .layout_layout1 .mm_menus_li.mm_has_sub:hover > a::after, .layout_layout1 .mm_menus_li.mm_has_sub.menu_hover > a::after {
    background-color: transparent !important;
  }
}