.page-manufacturer {
    #main {
        > ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 3rem;
        }
    }

    h1 {
        @extend .title-border-green;
        text-align: center;
        font-family: $font-secondary;
        font-size: 2rem;
    }

    .brand {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        padding: 0 15px;
    }

    .brand-infos {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            text-align: center;
        }
    }
}