// Mentions légales, LA PARAPHARMACIE DES ALPES, LES HORAIRES, Contact, CGV, pick up
.cms-id-2, .cms-id-6, .cms-id-10, .cms-id-9, .cms-id-7, .cms-id-8 {
    h1 {
        @extend .title-border-green;
        text-align: center;
        font-family: $font-secondary;
        font-size: 2rem;
    }

    .page-wrapper, .page-header {
        border: none;
    }

    .page-header {
        background: none;
    }
}
