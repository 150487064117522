.page-cart {
    .cart-grid {
        margin-top: 2rem;
    }

    .card-header {
        @extend .title-border-green;
        text-align: center;
        font-family: $font-secondary;
        font-size: 2rem;
        background: none;
        margin-bottom: 2rem;
        text-transform: capitalize;
        padding: 0;
    }

    .cart-container, .card-header {
        border: none;
    }
}
