.breadcrumb {
  background-color: transparent;
  margin-top: 25px;

  .breadcrumb-item {
    a {
      color: #393939;
    }
  }

  .breadcrumb-item.active {
    color: $color-primary;
  }
}

.title-border-green {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 15%;
    height: 1px;
    background-color: $color-primary;
    left: 50%;
    transform: translate(-50%);
  }
}
@include media-breakpoint-mobile {
  #menudubas {
    margin-bottom: 17px;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.35);
  }
}
